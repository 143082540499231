const Performances = ({ image, alt, objPosition, paragraph }) => (
  <section className="bg-near-white pv5">
    <div className="home-performances-grid container pv4 sm:pv5">
      <h2 className="section-heading mb4 pb3 sm:pb4">Performances</h2>

      <Img
        fluid={image}
        alt={alt}
        objPosition={objPosition}
        className="br2 aspect-ratio aspect-ratio--4x3"
      />

      <Info paragraph={paragraph} />
    </div>
  </section>
)

/*
 *
 * Info
 *
 */

const Info = ({ paragraph }) => (
  <div>
    <h2 className="dn section-heading mb4">Performances</h2>

    <p className="section-subheading-small mv4 measure-medium">
      Bio, Schedule & Videos
    </p>

    <p className="pb1 measure-medium lh-copy">{paragraph}..</p>

    <Link to="/performances/" className="link-inline dib mt4 f4">
      See All Performances
    </Link>
  </div>
)

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'
import { Link } from 'gatsby'

import Img from '../components/Img'

export default Performances
