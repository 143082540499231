const Contact = ({ image, alt, objPosition, description }) => (
  <section className="home-contact-grid container mt4 sm:mt5 mb3 pv5">
    <h2 className="section-heading mb4 pb3 sm:pb4">Contact</h2>

    <Img
      fluid={image}
      alt={alt}
      objPosition={objPosition}
      className="br2 aspect-ratio aspect-ratio--4x3"
    />

    <Info description={description} />
  </section>
)

/*
 *
 * Info
 *
 */

const Info = ({ description }) => (
  <div>
    <h2 className="dn section-heading mb4">Contact</h2>

    <p className="section-subheading-small mv4 pt2 measure">
      Agents, Email &amp; Social&nbsp;Media
    </p>

    <p className="pb1 measure-medium lh-copy">{description}</p>

    <Link to="/contact/" className="link-inline dib mt4 f4">
      Contact Patrick
    </Link>
  </div>
)

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'
import { Link } from 'gatsby'

import Img from '../components/Img'

export default Contact
