const HomePage = ({ data }) => {
  const hero = data.homeYaml.homeHero
  const performances = data.homeYaml.performancesImage
  const teaching = data.homeYaml.teachingImage
  const gallery = data.homeYaml.gallery
  const contact = data.homeYaml.contact

  return (
    <Base>
      <main id="main-content" tabIndex="-1" className="sans-serif">
        <Hero
          heading={hero.heading}
          image={hero.image.childImageSharp.fluid}
          alt={hero.alt}
          objPosition={hero.objPosition}
          quote={hero.quote}
          className="home-hero"
        />

        <Performances
          image={performances.image.childImageSharp.fluid}
          alt={performances.alt}
          objPosition={performances.objPosition}
          paragraph={data.performancesYaml.performanceBio.slice(0, 1)}
        />

        <Teaching
          image={teaching.image.childImageSharp.fluid}
          alt={teaching.alt}
          objPosition={teaching.objPosition}
          paragraph={data.teachingYaml.teachingBio.slice(0, 1)}
        />

        <Gallery
          image={gallery.image.childImageSharp.fluid}
          alt={gallery.alt}
          objPosition={gallery.objPosition}
          description={gallery.description}
        />

        <Contact
          image={contact.image.childImageSharp.fluid}
          alt={contact.alt}
          objPosition={contact.objPosition}
          description={contact.description}
        />
      </main>
    </Base>
  )
}

/*
 *
 * Queries
 * 
 */

export const query = graphql`
  query HomePageQuery {
    homeYaml {
      homeHero {
        heading
        image {
          childImageSharp {
            fluid(maxWidth: 1120) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        objPosition
        quote
      }
      performancesImage {
        image {
          childImageSharp {
            fluid(maxWidth: 931) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        objPosition
      }
      teachingImage {
        image {
          childImageSharp {
            fluid(maxWidth: 931) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        objPosition
      }
      gallery {
        image {
          childImageSharp {
            fluid(maxWidth: 931) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        objPosition
        description
      }
      contact {
        image {
          childImageSharp {
            fluid(maxWidth: 931) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        objPosition
        description
      }
    }
    performancesYaml {
      performanceBio
    }
    teachingYaml {
      teachingBio
    }
  }
`

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'
import { graphql } from 'gatsby'

import Contact from '../sections/HomeContact'
import Gallery from '../sections/HomeGallery'
import Performances from '../sections/HomePerformances'
import Teaching from '../sections/HomeTeaching'

import Base from '../components/Base'
import Hero from '../components/Hero'

export default HomePage
