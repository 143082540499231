const Teaching = ({ image, alt, objPosition, paragraph }) => (
  <section className="home-teaching-grid container mv4 sm:mv5 pv5">
    <h2 className="section-heading mb4 pb3 sm:pb4">Teaching</h2>

    <Img
      fluid={image}
      alt={alt}
      objPosition={objPosition}
      className="br2 aspect-ratio aspect-ratio--4x3"
    />

    <Info paragraph={paragraph} />
  </section>
)

/*
 *
 * Info
 *
 */

const Info = ({ paragraph }) => (
  <div>
    <h2 className="dn section-heading mb4">Teaching</h2>

    <p className="section-subheading-small mv4 pt2 measure-tight">
      Bio, Schedule, Students & The&nbsp;Jane&nbsp;Raftery Award
    </p>

    <p className="pb1 measure-medium lh-copy">{paragraph}..</p>

    <Link to="/teaching/" className="link-inline dib mt4 f4">
      See All Teaching
    </Link>
  </div>
)

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'
import { Link } from 'gatsby'

import Img from '../components/Img'

export default Teaching
